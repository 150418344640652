import { api } from './dataService';
import { HTTP_METHODS } from 'constant';

// CREATE ACCOUNT

export const createAccount = async (formData: {email: string; password: string;}) => {
    const res = await api('/account/signup', {
        method: HTTP_METHODS.POST,
        data: JSON.stringify(formData)
    })

    return res;
}

// SIGN IN

export const signIn = async (formData: {email: string; password: string;}) => {
    const res = await api('/api/auth/signin', {
        method: HTTP_METHODS.POST,
        data: JSON.stringify(formData)
    })

    return res;
}