type Object = {
    [key: string]: { name: string; value: string; }
}

const errorMessages = {
    email: {
        missing: 'The email field is required.',
        invalid: 'Please enter a valid email address in the format example@example.com.',
        exists: 'The email address you entered is already associated with an existing account.',
        blocked: 'Your email address has been blocked from accessing our services. Please contact support for further assistance.',
        sending: 'We encountered an issue while sending an email to the provided address.'
    },
    password: {
        invalid: 'Invalid password'
    }
}

const HTTP_METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE'
}

export const normalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '99',
      borderRadius: '20px'
    },
};


export const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '99',
      borderRadius: '20px',
      height: 'calc(100vh - 2rem)',
      maxHeight: '70rem',
      overflow: 'hidden'
    },
};

export const createPropertySteps = {
    basicInformation: 1,
    branding: 2,
    confirmationScreen: 3
}

export const categories = [
    { name: 'Summary', value: 'general' },
    { name: 'Kitchen', value: 'kitchen' },
    { name: 'Laundry', value: 'laundry' },
    { name: 'Outside', value: 'outside' },
    { name: 'Safety', value: 'safety' }
]

export const houseKeepingValues: Object = {
    ['daily-included']: { name: 'Daily - Included', value: 'daily-included' },
    ['daily-extra-charge']: { name: 'Daily - Extra Charge', value: 'daily-extra-charge' },
    ['weekly-included']: { name: 'Weekly - Included', value: 'weekly-included' },
    ['weekly-extra-charge']: { name: 'Weekly - Extra Charge', value: 'weekly-extra-charge' },
    ['fortnightly-included']: { name: 'Fortnightly - Included', value: 'fortnightly-included' },
    ['fortnightly-extra-charge']: { name: 'Fortnightly - Extra Charge', value: 'fortnightly-extra-charge' },
    ['upon-request-included']: { name: 'Upon Request - Included', value: 'upon-request-included' },
    ['upon-request-extra-charge']: { name: 'Upon Request - Extra Charge', value: 'upon-request-extra-charge' }
}

export const parkingValues: Object = {
    ['on-site-free']: { name: 'On-Site Free', value: 'on-site-free' },
    ['on-site-chargeable']: { name: 'On-Site Chargeable', value: 'on-site-chargeable' },
    ['off-site-free']: { name: 'Off-Site Free', value: 'off-site-free' },
    ['off-site-chargeable']: { name: 'Off-Site Chargeable', value: 'off-site-chargeable' }
}

export const cancellationPolicyValues: Object = {
    ['non-refundable']: { name: 'Non Refundable', value: 'non-refundable' },
    ['7-days-cancellation']: { name: '7 Days In-House Cancellation', value: '7-days-cancellation' },
    ['14-days-cancellation']: { name: '14 Days In-House Cancellation', value: '14-days-cancellation' },
    ['28-days-cancellation']: { name: '28 Days In-House Cancellation', value: '28-days-cancellation' },
    ['none']: { name: 'Other', value: 'none' }
}

export const petsValues: Object = {
    free: { name: 'Yes - Free Of Charge', value: 'free' },
    chargeable:  { name: 'Yes - Chargeable', value: 'chargeable' },
    no: { name: 'No', value: 'no' }
}

export const checkInValues: Object = {
    ['meet-and-greet']: { name: 'Meet & Greet', value: 'meet-and-greet' },
    ['key-box']: { name: 'Key Box', value: 'key-box' }
}

export * from './shared';
export * from './navigation';
export {
    errorMessages,
    HTTP_METHODS
}