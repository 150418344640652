import { faHouse, faBuilding } from "@fortawesome/free-solid-svg-icons"

export const propertyConfig: any = {
    propertyType: {
        house: {
            name: 'House',
            value: 'house',
            icon: faHouse
        },
        bungalow: {
            name: 'Bungalow',
            value: 'bungalow',
            icon: faHouse
        },
        maisonette: {
            name: 'Maisonette',
            value: 'maisonette',
            icon: faBuilding
        },
        apartment: {
            name: 'Apartment',
            value: 'apartment',
            icon: faBuilding
        },
        duplex: {
            name: 'Duplex Apartment',
            value: 'duplex',
            icon: faBuilding
        },
        studio: {
            name: 'Studio',
            value: 'studio',
            icon: faBuilding
        }
    }
}